<template>
  <v-dialog v-model="show" persistent max-width="900px">
    <v-card>
      <v-card-title>
        Configuración de envío de correo y métodos de ingreso a la plataforma
      </v-card-title>
      <v-card-text>
        <app-tab-component :items="opciones" v-if="opciones.length > 0">
          <template v-slot:[`contenido`]="{ item }">
            <component
              :id_usuario="id_usuario"
              :is="item.component"
            ></component>
          </template>
        </app-tab-component>
      </v-card-text>
      <v-card-actions>
        <v-btn class="primary" @click="cerrarModal">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import metodoAutenticacion from "./tabsConfiguracionUsaurio/metodoAutenticacion.vue";
import metodoCorreoUsuario from "./tabsConfiguracionUsaurio/metodoCorreoUsuario.vue";
import permisosExtrasAsignados from "./tabsConfiguracionUsaurio/permisosExtrasAsignados.vue";

export default {
  name: "ModalconfiguracionUsuario",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    id_usuario: {
      type: Number,
    },
    evaluarRol: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    cerrarModal() {
      this.$emit("cerrar");
    },
  },
  computed: {
    showTab() {
      if (!this.evaluarRol) return false;

      const permisos = {};

      const rolesProveedor = [
        "ROLE_PROVEEDOR",
        "ROLE_PROVEEDOR_LIST_ADMIN_USUARIOS",
      ];
      const rolesAdminCorreo = [
        "ROLE_ADMINISTRAR_ACCESOS_CORREO_PERFIL_PROVEEDOR",
      ];

      if (this.haveRoles(rolesProveedor)) {
        permisos.showFa2 = false;
        permisos.showCorreo = true;
        permisos.showPermisos = true;
      }

      if (this.haveRoles(rolesAdminCorreo)) {
        permisos.showCorreo = false;
        permisos.showPermisos = true;
      }

      return permisos;
    },
    opciones() {
      return [
        {
          nombre: "Método de 2fa",
          component: metodoAutenticacion,
          hide_item: this.showTab.showFa2,
        },
        {
          nombre: "Método de correo",
          component: metodoCorreoUsuario,
          hide_item: this.showTab.showCorreo,
        },
        {
          nombre: "Permisos extra asignados",
          component: permisosExtrasAsignados,
          hide_item: this.showTab.showPermisos,
        },
      ];
    },
  },
};
</script>
<style scoped>
@media (min-width: 960px) {
  .grupo-detalles {
    gap: 24px;
  }
}
</style>
