<template>
  <v-dialog v-model="showEditarUsuario" persistent max-width="650px">
    <v-card class="py-4 px-4">
      <v-card-text>
        <h5 class="text-h5 secondary--text">
          {{ this.dataUsuario?.primer_nombre }}
          {{ this.dataUsuario?.primer_apellido }}
        </h5>

        <v-divider class="mt-4"></v-divider>

        <v-form class="mt-8" ref="formUpdateUser">
          <v-autocomplete
            v-if="haveRole('ROLE_DIRECCION_COMPRAS')"
            outlined
            v-model="form.id_institucion"
            label="Institución *"
            :rules="[rules.required]"
            :items="listaInstituciones"
            item-value="id"
            item-text="nombre_completo"
            @change="getUnidades(form.id_institucion)"
          ></v-autocomplete>

          <v-autocomplete
            outlined
            v-model="form.id_unidad"
            label="Unidad *"
            :rules="[rules.required]"
            :items="unidades"
            item-value="id"
            :item-text="
              (item) =>
                `${item?.nombre} - (${item.instituciones.codigo}) ${item?.institucion}`
            "
          >
            <template v-slot:item="{ item }">
              <div>
                <span>{{ item.nombre }}</span> -
                <span class="primary--text font-weight-bold">
                  ({{ item.instituciones.codigo }})
                  {{ item.institucion }}
                </span>
              </div>
            </template>
          </v-autocomplete>
          <v-text-field
            outlined
            v-model="form.email"
            label="Correo electrónico *"
            :rules="[rules.required, rules.correo]"
          />
          <v-text-field
            outlined
            label="Teléfono *"
            v-model="form.telefono"
            :rules="[rules.required, rules.telefono]"
          />
          <v-autocomplete
            outlined
            label="Profesión *"
            :items="profesiones"
            item-text="nombre"
            item-value="id"
            v-model="form.id_profesion"
            :rules="[rules.required]"
          />
          <v-autocomplete
            outlined
            label="Perfil *"
            :items="catalogoPerfiles"
            item-value="id"
            item-text="nombre"
            v-model="form.id_perfil"
            :disabled="[20, 34].includes(form.id_perfil)"
            :rules="[rules.required]"
          >
            <template v-slot:item="{ item }">
              <span
                :class="`${item.disabled ? 'grayMinsal' : 'primary'}--text`"
                >{{ item.nombre }}</span
              >
            </template>
            <template v-slot:selection="{ item }">
              <span
                :class="`${item.disabled ? 'grayMinsal' : 'primary'}--text`"
                >{{ item.nombre }}</span
              >
            </template>
          </v-autocomplete>
          <v-alert v-if="[20, 34].includes(form.id_perfil)" dense type="info">
            El cambio de perfil para usuarios de cumplimiento debe realizarse
            mediante una solicitud
          </v-alert>
          <v-autocomplete
            v-if="form.id_perfil == 30"
            :items="ctlRegiones"
            clearable
            item-text="nombre"
            item-value="id"
            label="Región a la que estará asignado *"
            no-data-text="Sin datos"
            outlined
            placeholder="Seleccione"
            v-model="form.id_region"
            @change="
              (value) => {
                fetchPaises(value);
                form.id_pais = null;
              }
            "
            :rules="[rules.region]"
          />
          <v-autocomplete
            v-if="form.id_perfil == 30"
            :items="ctlPaises"
            clearable
            item-text="nombre"
            item-value="id"
            label="País al que estará asignado *"
            no-data-text="Sin datos"
            outlined
            placeholder="Seleccione"
            v-model="form.id_pais"
            :enabled="form.id_region"
            :rules="[rules.pais]"
          />
          <v-file-input
            outlined
            required
            prepend-icon=""
            v-model="comprobante"
            accept="application/pdf"
            prepend-inner-icon="mdi-paperclip"
            label="Comprobante de nombramiento *"
            :rules="[rules.documento]"
          ></v-file-input>
          <v-text-field
            outlined
            label="Cargo funcional *"
            v-model="form.cargo_funcional"
            :rules="[rules.required]"
          />

          <vc-date-picker
            :min-date="minDate"
            locale="es"
            mode="date"
            :model-config="modelConfig"
            :popover="config_calendar"
            :value="form.fecha_caducidad"
            @input="updateFechaCaducidadUsuario"
          >
            <template v-slot="{ inputEvents }">
              <v-text-field
                :value="form.fecha_caducidad"
                class="border roundend focus:outline-none focus:border-blue-300"
                label="Fecha de caducidad *"
                outlined
                prepend-inner-icon="mdi-calendar"
                readonly
                v-on="inputEvents"
                :rules="[rules.required]"
              />
            </template>
          </vc-date-picker>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="12" sm="6">
            <v-btn text block color="secondary" @click="limpiarFormulario()">
              Cancelar
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6">
            <v-btn block color="secondary" @click="showModalVerificar = true">
              Actualizar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <ConfirmationDialogComponent
      :show="showModalVerificar"
      btnConfirmar="Confirmar"
      :title="`¿Desea actualizar los datos del usuario?`"
      message="Esta acción será totalmente irreversible"
      @close="showModalVerificar = false"
      @confirm="updateUsuario()"
    />
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import moment from "moment";
import ConfirmationDialogComponent from "@/components/ConfirmationDialogComponent.vue";

function validarNumero(value) {
  if (value) {
    return !!(
      value.charAt(0) == "2" ||
      value.charAt(0) == "6" ||
      value.charAt(0) == "7"
    );
  } else {
    return true;
  }
}

function numero(value) {
  if (value === null) return false;
  return /^([0-9]+)*$/.test(value);
}

function isEmail(value) {
  if (value === null) return false;
  return /^([a-zA-Z0-9./^S+$/<*>!#$%&'+/=?^_`{|}~-]+([s]{0}))+?@[a-zA-Z]+([.]{1})[a-zA-Z]+[s]{0}[.]?[a-zA-Z](([.]{0,1})([a-zA-Z]{2})+)*$/.test(
    value
  );
}

function fileNameValid(value) {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
}

export default {
  name: "editarUsuarioComponent",
  components: {
    ConfirmationDialogComponent,
  },
  props: ["creado", "refreshData"],
  data: () => ({
    comprobante: null,
    modelConfig: {
      type: "string",
      mask: "DD-MM-YYYY",
    },
    config_calendar: {
      visibility: "click",
    },
    showModalVerificar: false,
    unidades: [],
    ctlRegiones: [],
    ctlPaises: [],
    form: {
      id_institucion: null,
      id_unidad: null,
      email: null,
      telefono: null,
      id_profesion: null,
      id_perfil: null,
      cargo_funcional: null,
      fecha_caducidad: null,
      id_region: null,
      id_pais: null,
    },
  }),
  async mounted() {
    if (this.form.id_institucion !== null) {
      const response = await this.services.UnidadesServices.getAllUnidades({
        id_institucion: this.form.id_institucion,
      });
      this.unidades = response.data;
      if (this.form.id_perfil == 30) {
        this.fetchRegiones();
      }
    }
  },
  computed: {
    ...mapState("usuariosModule", [
      "showEditarUsuario",
      "dataUsuario",
      "profesiones",
      "perfiles",
      "instituciones",
    ]),
    minDate() {
      return moment().add(2, "days").format("YYYY-MM-DD");
    },
    listaInstituciones() {
      return this.instituciones.map((institucion) => ({
        ...institucion,
        nombre_completo: `${institucion.codigo} - ${institucion.nombre}`,
      }));
    },
    catalogoPerfiles() {
      return this.perfiles.map((perfil) => ({
        ...perfil,
        disabled: [20, 34].includes(perfil.id),
      }));
    },
    rules() {
      return {
        required: (value) => !!value || "Este es un campo requerido",
        telefono: (value) =>
          (validarNumero(value) && numero(value) && value?.length === 8) ||
          "Ingrese un número de teléfono válido, ingrese solamente números",
        correo: (value) =>
          isEmail(value) || "Ingrese un correo electrónico válido",
        documento: (value) =>
          fileNameValid(value) ||
          "Ingrese un documento válido, se aceptan las siguientes extensiones: PDF",
        region: (value) => {
          return this.form.id_perfil == 30
            ? !!value || "Este es un campo requerido"
            : true;
        },
        pais: (value) => {
          return this.form.id_perfil == 30
            ? !!value || "Este es un campo requerido"
            : true;
        },
      };
    },
  },
  methods: {
    ...mapMutations("usuariosModule", [
      "setShowEditarUsuario",
      "setDataUsuario",
    ]),
    limpiarFormulario() {
      Object.entries(this.form).forEach(([key, value]) => {
        this.form[key] = null;
      });
      this.comprobante = null;
      this.setShowEditarUsuario(false);
    },

    updateFechaCaducidadUsuario(value) {
      this.form.fecha_caducidad = value;
    },
    async downloadDocument() {
      try {
        const response = await this.services.Usuarios.getUrlDocumento({
          ruta: route,
        });

        if (response) {
          const blob = new Blob([response.data], {
            type: "application/pdf",
          });

          const file = new File([blob], "documento.pdf", {
            type: "application/pdf",
          });

          // Descargar el archivo
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(file);
          link.download = route.toString();
          link.click();
        }
      } catch (error) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el archivo.",
          type: "error",
        });
      } finally {
      }
    },

    async updateUsuario() {
      if (this.$refs.formUpdateUser.validate()) {
        const form = new FormData();
        this.form.fecha_caducidad = moment(
          this.form.fecha_caducidad,
          "DD-MM-YYYY"
        ).format("YYYY-MM-DD");
        Object.entries(this.form).forEach(([key, value]) =>
          form.append(key, value)
        );
        form.append("comprobante", this.comprobante);

        const response = await this.services.SolicitudesUsuarios.updateUsuario(
          this.dataUsuario?.Empleados[0]?.Usuario?.id,
          this.form
        );

        if (response?.status === 200) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "Los datos han sido actualizados satisfactoriamente",
          });
          this.showModalVerificar = false;
          this.limpiarFormulario();
          this.$props.refreshData();
        }
      } else {
        this.showModalVerificar = false;
      }
    },
    async getUnidades(idInstitucion) {
      const response = await this.services.UnidadesServices.getAllUnidades({
        id_institucion: idInstitucion,
      });
      this.unidades = response.data;
    },
    async fetchRegiones() {
      try {
        const response = await this.services.Paises.getListaRegiones();
        if (response) {
          this.ctlRegiones = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchPaises(idRegion) {
      try {
        const response = await this.services.Paises.getListaPaises({
          region: idRegion,
        });
        if (response) {
          this.ctlPaises = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  watch: {
    "form.id_institucion"(value) {
      this.getUnidades(value);
    },
    "form.id_perfil"(value) {
      if (value == 30) {
        this.fetchRegiones();
      }
    },
    "form.id_region"(value) {
      if (value && this.form.id_perfil == 30) {
        this.fetchPaises(value);
      }
    },
    dataUsuario: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (!newVal) return;
        this.form = {
          id_institucion:
            newVal?.Empleados?.[0]?.EmpleadoUnidads?.[0]?.Unidad
              ?.id_institucion ?? null,
          id_unidad:
            newVal?.Empleados?.[0]?.EmpleadoUnidads?.[0]?.Unidad?.id ?? null,
          email: newVal?.Empleados?.[0]?.Usuario?.email ?? null,
          telefono: newVal?.telefono?.toString().replaceAll("-", "") ?? null,
          id_profesion: newVal?.id_profesion ?? null,
          id_perfil: newVal?.Empleados?.[0]?.Usuario?.Perfils?.[0]?.id ?? null,
          cargo_funcional:
            newVal?.Empleados?.[0]?.Usuario?.cargo_funcional ?? null,
          fecha_caducidad: newVal?.Empleados?.[0]?.Usuario?.fecha_caducidad
            ? moment(
                newVal.Empleados[0].Usuario.fecha_caducidad,
                "YYYY-MM-DD"
              ).format("DD-MM-YYYY")
            : null,
          id_region:
            newVal?.Empleados?.[0]?.EmpleadoUnidads?.[0]?.Pais?.CtlRegion?.id ??
            null,
          id_pais:
            newVal?.Empleados?.[0]?.EmpleadoUnidads?.[0]?.Pais?.id ?? null,
        };
      },
    },
  },
};
</script>
