<template>
  <v-card elevation="4">
    <v-card-text>
      <v-row v-if="!modificar">
        <ProgresiveLoadAutocompleteOld
          v-model="id_usuario_seleccionado"
          class="flex-grow-1 flex-shrink-1"
          style="min-width: 200px"
          label="Usuario"
          placeholder="Seleccione el usuario"
          itemText="email"
          itemValue="id"
          hide-details
          clearable
          :service="service"
          ref="progresive"
        />
      </v-row>
      <v-row>
        <template v-if="modificar">
          <v-col cols="8" md="8">
            <v-autocomplete
              v-model="id_rol"
              :items="roles"
              item-text="name"
              item-value="id"
              outlined
              clearable
              label="Seleccione el rol"
              hide-details
            />
          </v-col>
          <v-col class="mt-2" cols="2" md="2">
            <v-btn @click="agregarRolModal()">Agregar</v-btn>
          </v-col>
        </template>
        <v-col cols="12" md="12">
          <v-data-table :headers="headers" :items="rolesUsuario">
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Roles asignados</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }" v-if="modificar">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-on="on"
                    v-bind="attrs"
                    class="d-flex align-center justify-center"
                    style="gap: 8px"
                    @click="eliminar(item)"
                  >
                    <v-icon size="20px !important">mdi-delete</v-icon>
                  </div>
                </template>
                <span>Eliminar permiso</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
        <template v-if="modificar">
          <v-col cols="8" md="8">
            <v-autocomplete
              v-model="id_perfil"
              :items="perfiles"
              item-text="nombre"
              item-value="id"
              outlined
              clearable
              label="Seleccione el perfil"
              hide-details
            />
          </v-col>
          <v-col class="mt-2" cols="2" md="2">
            <v-btn @click="agregarPerfilModal()">Agregar</v-btn>
          </v-col>
        </template>
        <v-col cols="12" md="12">
          <v-data-table :headers="headers" :items="perfilesUsuario">
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Perfiles asignados</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }" v-if="modificar">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-on="on"
                    v-bind="attrs"
                    class="d-flex align-center justify-center"
                    style="gap: 8px"
                    @click="eliminar(item, true)"
                  >
                    <v-icon size="20px !important">mdi-delete</v-icon>
                  </div>
                </template>
                <span>Eliminar permiso</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              {{ item.nombre }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <ConfirmationDialogComponent
        :show="modalConfirmacion"
        btnConfirmar="Confirmar"
        :title="`¿Desea realizar la acción`"
        @close="(modalConfirmacion = false), (elemento = null)"
        :callback="callback"
      />
    </v-card-text>
  </v-card>
</template>
<script>
import ProgresiveLoadAutocompleteOld from "@/components/utils/ProgresiveLoadAutocompleteOld.vue";
import ConfirmationDialogComponent from "../../../components/ConfirmationDialogComponent.vue";

import UsuariosServicesV2 from "@/services/Usuarios.services.v2";
const servicio = UsuariosServicesV2.obtenerUsuarios;

import { Fragment } from "vue-fragment";

export default {
  name: "cardDetallePermisos",
  components: {
    Fragment,
    ProgresiveLoadAutocompleteOld,
    ConfirmationDialogComponent,
  },
  data: () => ({
    service: servicio,
    comparar: false,
    id_perfil: null,
    id_rol: null,
    id_usuario_seleccionado: null,
    roles: [],
    perfiles: [],
    rolesUsuario: [],
    perfilesUsuario: [],
    modalConfirmacion: false,
    callback: null,
    elemento: null,
  }),
  computed: {
    headers() {
      const h = [
        { text: "ID", value: "id", sortable: false },
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "name",
        },
      ];
      if (this.modificar)
        h.push({ text: "Acciones", value: "actions", sortable: false });
      return h;
    },
  },
  props: {
    id_usuario: {
      type: Number,
      default: 0,
    },
    modificar: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    id_usuario_seleccionado: {
      handler: function (value) {
        if (value) this.cargarPermisosUsuario(value);
      },
      immediate: true,
    },
    id_usuario: {
      handler: function (value) {
        if (value) this.cargarPermisosUsuario(value);
      },
      immediate: true,
    },
  },
  methods: {
    async obtenerPerfiles() {
      const { data } = await this.services.permisosServices.obtenerPerfiles();
      this.perfiles = data;
    },
    async obtenerPerfilesUsuario(id_usuario) {
      const { data } = await this.services.permisosServices.obtenerPerfiles(
        id_usuario
      );
      this.perfilesUsuario = data;
    },
    async obtenerRoles() {
      const { data } = await this.services.permisosServices.obtenerRoles();
      this.roles = data;
    },
    async obtenerRolesUsuario(id_usuario) {
      const { data } = await this.services.permisosServices.obtenerRoles(
        id_usuario
      );
      this.rolesUsuario = data;
    },
    async cargarPermisos() {
      await this.obtenerPerfiles();
      await this.obtenerRoles();
    },
    async cargarPermisosUsuario(id_usuario) {
      await this.obtenerPerfilesUsuario(id_usuario);
      await this.obtenerRolesUsuario(id_usuario);
    },
    async agregarPerfil() {
      await this.services.permisosServices.agregarPerfil(
        this.id_usuario,
        this.id_perfil
      );
      this.cargarPermisosUsuario(this.id_usuario);
      this.id_perfil = null;
    },
    async agregarRol() {
      await this.services.permisosServices.agregarRol(
        this.id_usuario,
        this.id_rol
      );
      this.cargarPermisosUsuario(this.id_usuario);
      this.id_rol = null;
    },
    async eliminarRol() {
      await this.services.permisosServices.eliminarRolUsuario(
        this.id_usuario,
        this.id_rol
      );
      this.cargarPermisosUsuario(this.id_usuario);
    },
    async eliminarPerfil() {
      await this.services.permisosServices.eliminarPerfilUsuario(
        this.id_usuario,
        this.id_perfil
      );
      this.cargarPermisosUsuario(this.id_usuario);
    },
    eliminar(item, perfil = false) {
      this.modalConfirmacion = true;
      this.elemento = item;
      this.callback = async () => {
        if (perfil) {
          await this.services.permisosServices.eliminarPerfilUsuario(
            this.id_usuario,
            item.id
          );
        } else {
          await this.services.permisosServices.eliminarRolUsuario(
            this.id_usuario,
            item.id
          );
        }
        this.cargarPermisosUsuario(this.id_usuario);
      };
    },
    agregarRolModal() {
      this.modalConfirmacion = true;
      this.callback = this.agregarRol;
    },
    agregarPerfilModal() {
      this.modalConfirmacion = true;
      this.callback = this.agregarPerfil;
    },
    cerrarTodo() {
      this.id_perfil = null;
      this.id_rol = null;
      this.id_usuario_seleccionado = null;
      this.roles = [];
      this.perfiles = [];
      this.rolesUsuario = [];
      this.perfilesUsuario = [];
      this.modalConfirmacion = false;
      this.callback = null;
      this.elemento = null;
    },
  },
  mounted() {
    this.cargarPermisos();
    this.cargarPermisosUsuario(this.id_usuario);
  },
  beforeDestroy() {
    this.cerrarTodo();
  },
};
</script>
