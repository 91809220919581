<template>
  <v-dialog
    v-model="dialog"
    hide-overlay
    persistent
    transition="dialog-bottom-transition"
    fullscreen
  >
    <v-card>
      <v-card-title> Edición de permisos </v-card-title>
      <v-card-actions>
        <v-row no-gutters>
          <v-col cols="12" md="12">
            <v-btn color="primary" @click="cerrarModal">Cerrar</v-btn>
          </v-col>
          <v-col cols="12" md="12">
            <v-switch v-model="comparar" label="Comparar perfil" class="mr-4" />
          </v-col>
        </v-row>
      </v-card-actions>
      <v-card-text class="scrollable-card-text">
        <v-row>
          <v-col cols="12" :md="comparar">
            <cardDetallePermisos
              :id_usuario="id_usuario"
              :modificar="true"
              ref="modalUno"
            />
          </v-col>
          <v-col cols="12" md="6" v-if="comparar">
            <cardDetallePermisos ref="modalDos" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import cardDetallePermisos from "./cardDetallePermisos.vue";
export default {
  name: "modalPerfiles",
  components: {
    cardDetallePermisos,
  },
  data: () => ({
    comparar: false,
  }),
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    id_usuario: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    id: {
      get() {
        return this.id_usuario ?? false;
      },
      set(value) {
        return this.id_usuario;
      },
    },
  },
  methods: {
    cerrarModal() {
      this.$emit("cerrar");
      //   this.$refs.modalDos.cerrarTodo();
      //   this.$refs.modalUno.cerrarTodo();
    },
  },
};
</script>
<style scoped>
.scrollable-card-text {
  max-height: 80vh;
  overflow-y: auto;
}
</style>
